import axios from 'axios'
import jwt from 'jsonwebtoken'

const domain = process.env.VUE_APP_URL_AWS_MONITOR

const axiosInstance = axios.create({
  domain,
  baseURL: process.env.VUE_APP_URL_AWS_MONITOR,
})

axiosInstance.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('user'))
  const token = jwt.sign({ user }, process.env.VUE_APP_SECRET_JWT)

  config.headers.authorization = token
  return config
}, function (error) {
  return Promise.reject(error)
})

export default axiosInstance
